import React, { useState } from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import { AnchorLink } from "gatsby-plugin-anchor-links";

const Nav = () => {
  const [open, setOpen] = useState(false);

  const { fixed } = useStaticQuery(graphql`
    query NavLogoQuery {
      file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          fixed(width: 175) {
            ...GatsbyImageSharpFixed_withWebp_tracedSVG
          }
        }
      }
    }
  `).file.childImageSharp;
  // urls are all lower case to match Links component and Contact component ids
  const cta = {
    title: "Request an estimate",
    url: "/#estimate",
  };

  return (
    <>
      {/* nav container */}
      <div className="h-28 fixed top-0 left-0 z-50 w-screen py-3 px-4 sm:px-6 lg:px-8 bg-white border-b border-white antialiased">
        <nav className="relative flex items-center justify-between lg:justify-start">
          <div className="flex items-center flex-grow">
            <div className="flex items-center justify-between w-full md:w-auto">
              <AnchorLink to="/" className={"flex items-center"}>
                <img src={fixed.src} alt={"Logo"} className={"w-24 md:w-40"} />
              </AnchorLink>
            </div>
          </div>
          <div
            className={
              "flex flex-col flex-shrink-0 items-end text-xs sm:text-sm md:text-base leading-tight text-right"
            }
          >
            <span className={"inline-block uppercase text-right leading-tight"}>
              Call today{" "}
              <a className={"text-primary"} href={"tel:+1-920-210-5266"}>
                (920)-210-5266
              </a>
              <br />
              <span className={"text-sm"}>24/7 service</span>
            </span>
            <span className="mt-2 inline-flex rounded-md shadow-sm flex justify-end">
              <AnchorLink
                to={cta.url}
                className="inline-flex items-center px-2 md:px-4 py-2 border border-transparent font-medium rounded-sm text-white bg-primary hover:bg-primary-alt focus:outline-none focus:border-primary focus:shadow-outline-primary active:bg-primary-alt transition ease-in-out duration-150"
              >
                {cta.title}
                <svg
                  fill="currentColor"
                  className="ml-1 md:ml-3 -mr-1 h-5 w-5"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.95 10.707l.707-.707L8 4.343 6.586 5.757 10.828 10l-4.242 4.243L8 15.657l4.95-4.95z"
                    fillRule="evenodd"
                  />
                </svg>
              </AnchorLink>
            </span>
          </div>
        </nav>
      </div>

      {/*<div*/}
      {/*  className={`${*/}
      {/*    open ? "fixed" : "hidden"*/}
      {/*  } top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden w-screen z-50`}*/}
      {/*>*/}
      {/*  <div className="rounded-lg shadow-md">*/}
      {/*    <div className="rounded-lg bg-white shadow-xs overflow-hidden">*/}
      {/*      <div className="px-5 pt-4 flex items-center justify-end">*/}
      {/*        <div className="-mr-2 -mt-2">*/}
      {/*          <button*/}
      {/*            type="button"*/}
      {/*            className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-600 hover:bg-gray-200 focus:outline-none focus:bg-gray-200 focus:text-gray-600 transition duration-150 ease-in-out"*/}
      {/*            onClick={() => setOpen(!open)}*/}
      {/*            aria-label="Open/Close Menu"*/}
      {/*          >*/}
      {/*            <svg*/}
      {/*              className="h-6 w-6"*/}
      {/*              stroke="currentColor"*/}
      {/*              fill="none"*/}
      {/*              viewBox="0 0 24 24"*/}
      {/*            >*/}
      {/*              <path*/}
      {/*                strokeLinecap="round"*/}
      {/*                strokeLinejoin="round"*/}
      {/*                strokeWidth="2"*/}
      {/*                d="M6 18L18 6M6 6l12 12"*/}
      {/*              />*/}
      {/*            </svg>*/}
      {/*          </button>*/}
      {/*        </div>*/}
      {/*      </div>*/}
      {/*      <div className="px-2 pt-2 pb-3">*/}
      {/*        {links.map((link, i) => (*/}
      {/*          //   Wrapper for onClick to work (cannot attach onClick to Link)*/}
      {/*          <div onClick={() => setOpen(!open)} key={i}>*/}
      {/*            <AnchorLink*/}
      {/*              to={link.url}*/}
      {/*              className={`${*/}
      {/*                i > 0 ? "mt-1" : ""*/}
      {/*              } block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:underline hover:bg-gray-50 focus:outline-none focus:underline focus:bg-gray-50 transition duration-150 ease-in-out`}*/}
      {/*            >*/}
      {/*              {link.title}*/}
      {/*            </AnchorLink>*/}
      {/*          </div>*/}
      {/*        ))}*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*</div>*/}
    </>
  );
};

export default Nav;
