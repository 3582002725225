const defaultTheme = require("tailwindcss/defaultTheme");
const tailwindUiTheme = require("@tailwindcss/ui")().config.theme;

const plugin = require("tailwindcss/plugin");

const gradientsPlugin = plugin(function ({ addUtilities }) {
  const newUtilities = {
    ".bg-gradient": {
      background: `linear-gradient(90deg, ${tailwindUiTheme.colors.blue["800"]} 0%, ${tailwindUiTheme.colors.blue["800"]} 35%, ${tailwindUiTheme.colors.blue["900"]} 100%)`,
    },
  };

  addUtilities(newUtilities, ["responsive", "hover"]);
});

const fullBleedPlugin = plugin(function ({ addUtilities }) {
  addUtilities(
    {
      ".full-bleed": {
        width: "100vw",
        "margin-left": "50%",
        transform: "translateX(-50%)",
        "max-width": "none",
      },
      ".full-bleed-disable": {
        width: "initial",
        "margin-left": "initial",
        transform: "initial",
        "max-width": "initial",
      },
    },
    ["responsive"]
  );
});

// See https://tailwindcss.com/docs/configuration for details
module.exports = {
  purge: ["./src/**/*.js", "./src/**/*.tsx"],
  theme: {
    // screens: {
    //   xs: "360px",
    //   sm: "640px",
    //   // => @media (min-width: 640px) { ... }

    //   md: "768px",
    //   // => @media (min-width: 768px) { ... }

    //   lg: "1024px",
    //   // => @media (min-width: 1024px) { ... }

    //   xl: "1280px",
    //   // => @media (min-width: 1280px) { ... }
    // },
    extend: {
      colors: {
        primary: tailwindUiTheme.colors.blue["800"],
        "primary-alt": tailwindUiTheme.colors.blue["700"],
        accent: tailwindUiTheme.colors.yellow["400"],
        "accent-alt": tailwindUiTheme.colors.yellow["300"],
      },
      fontFamily: {
        accent: ["'Stylish', 'cursive'", ...defaultTheme.fontFamily.mono],
        display: ["'Montserrat'", ...defaultTheme.fontFamily.sans],
      },
      boxShadow: {
        // outline: "0 0 0 3px rgba(155, 28, 28, 0.5)",
        outline: "0 0 0 3px rgba(55, 65, 81, 0.5)",
      },
    },
  },
  variants: {
    backgroundColor: ["responsive", "hover", "focus", "disabled"],
    textColor: ["responsive", "hover", "focus", "disabled"],
  },
  // https://github.com/tailwindcss/custom-forms
  plugins: [
    require("@tailwindcss/custom-forms"),
    require("@tailwindcss/ui"),
    gradientsPlugin,
    fullBleedPlugin,
  ],
};
